
import { defineComponent, ref, watch, computed } from 'vue';
import { TreeSelect } from 'ant-design-vue';
import { qmOptions, UndefinedString } from '../types';
import useSearchConditions from '@/hooks/localRegistrationReport/useSearchCondition';
import useTable from '@/hooks/localRegistrationReport/useTable';
import {
    queryReportDataInfo,
    getGroupCalculationRuleSelection,
} from '@/API/localRegistration';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
import downloadFile from '@/utils/claim/downloadFile';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default defineComponent({
    components: {
        DM,
    },
    setup() {
        const {
            spaceSize,
            viewTypes,
            selectedViewType,
            selectViewType,
            initBu,
            bu,
            buList,
            brand,
            brandList,
            make,
            makeList,
            typeClass,
            typeClassList,
            region,
            regionList,
            subRegion,
            subRegionList,
            province,
            provinceList,
            city,
            cityList,
            year,
            openYear,
            qm,
            handlerOpenYearChange,
            handlerPanelYearChange,
            restrictedCity,
            restrictedCityType,
            dealerName,
            dealerGroup,
            isVAN,
            paramsCheck,
            calculationRule,
            calculationRuleType,
        } = useSearchConditions();

        const getRuleType = async () => {
            if (bu.value.length) {
              calculationRuleType.value = await getGroupCalculationRuleSelection(bu.value, 'Y')
            } else {
              calculationRuleType.value = []
            }
        };
        watch(
            //* bu 改变 获取 brand 和 region
            bu,
            (newBu: any) => {
                if (newBu) {
                    calculationRule.value = undefined;
                    getRuleType();
                }
            },
            {
                immediate: true,
            }
        );

        const dataView = ref<UndefinedString>(undefined);
        const dataViewList = [
            { title: 'Province', value: 'PROVINCE' },
            { title: 'City', value: 'CITY' },
        ];

        const dealerCodeType = ref<UndefinedString>(undefined);
        const dealerCodeTypeList = [
            { title: 'Dealer Code (Cofico)', value: 'Dealer Code (Cofico)' },
            { title: 'Dealer Code (SWT)', value: 'Dealer Code (SWT)' },
            { title: 'All', value: '' },
        ];

        const { commonColumns, genAllColumns, tableWidth, colHeight } = useTable(
            year.value,
            isVAN,
            'region-table',
            'region'
        );
        const dataSource = ref<any>([]);
        genAllColumns(dataView.value);

        const columns = computed(() => {
          return commonColumns.value.filter((item: any) => {
            if (selectedViewType.value === 1 && item.key === 'make') {
              return false
            } else {
              return true
            }
          })
        })

        const reset = () => {
            bu.value = [initBu];
            brand.value = 'All'; // make 跟着brand变化
            typeClass.value = undefined;
            region.value = 'All'; // subregion 跟着region变化
            province.value = 'All'; // city 跟着province变化
            year.value = undefined;
            qm.value = undefined;
            restrictedCity.value = 'All';
            dealerName.value = '';
            dealerGroup.value = '';
            calculationRule.value = undefined;
            dataView.value = undefined;
            dealerCodeType.value = undefined;
        };

        const chooseTotalColIndex = () => {
            let totalColIndex: any;
            switch (dataView.value) {
                case 'Province':
                    totalColIndex = {
                        provinceEn: 'Total',
                    };
                    break;
                case 'City':
                    totalColIndex = {
                        restrictedCity: 'Total',
                    };
                    break;
                case 'Region':
                case 'Subregion':
                case 'Make':
                    totalColIndex = {
                        region: 'Total',
                    };
                    break;
                case 'Dealer Group':
                    totalColIndex = {
                        dealerGroupEn: 'Total',
                    };
                    break;
                case 'Type Class':
                    totalColIndex = {
                        ifCriticalModel: 'Total',
                    };
                    break;
                default:
                    totalColIndex = {
                        cityEn: 'Total',
                    };
            }
            return totalColIndex;
        };

        const genQM = (val: string) => {
            const maps = {
                Q1: '03',
                Q2: '06',
                Q3: '09',
                Q4: '12',
            };
            if (val[0] === 'Q') {
                return maps[val];
            }
            return val;
        };

        const genParams = () => {
            return {
                buList: bu.value,
                brand: brand.value === 'All' ? '' : brand.value,
                make: make.value === 'All' ? '' : make.value,
                typeClass: typeClass.value,
                region: region.value === 'All' ? '' : region.value,
                subregion: subRegion.value === 'All' ? '' : subRegion.value,
                province: province.value === 'All' ? '' : province.value,
                city: city.value === 'All' ? '' : city.value,
                year: year.value,
                qm: qm.value ? genQM(qm.value) : undefined,
                restrictedCity:
                    restrictedCity.value === 'All' ? '' : restrictedCity.value,
                ruleCodeList: calculationRule.value ? calculationRule.value.split(',') : [],
                dealerName: dealerName.value,
                dealerGroup: dealerGroup.value,
                dataView:
                    selectedViewType.value === 1 || dataView.value === undefined
                        ? 'DEALER'
                        : dataView.value,
                dealerCodeType: dealerCodeType.value,
                type: 'REGION',
            };
        };

        const search = () => {
            if (paramsCheck()) return;
            const params = genParams();
            queryReportDataInfo(params).then((res: any) => {
                const reportVOList = res.reportVOList;
                const totalVO = res.totalVO;
                if (reportVOList && totalVO) {
                    const fixedNames = [
                        'localAndRtPercent',
                        'localAndRegPercent',
                        'rtVol',
                        'rtRegVol',
                        'rtRegLocalVol',
                    ];
                    reportVOList.forEach((report: any, index: number) => {
                        fixedNames.forEach((name: string) => {
                            Object.keys(report[name]).forEach(
                                (attr: string) => {
                                    report[`${name}_${attr}`] =
                                        report[name][attr];
                                    report['no'] = index + 1;
                                }
                            );
                        });
                    });
                    fixedNames.forEach((name: string) => {
                        Object.keys(totalVO[name]).forEach((attr: string) => {
                            totalVO[`${name}_${attr}`] = totalVO[name][attr];
                        });
                    });
                    const totalColIndex = chooseTotalColIndex();
                    dataSource.value = [
                        ...reportVOList,
                        { ...totalColIndex, ...totalVO, type: 'totalRow' },
                    ];
                } else {
                    dataSource.value = [];
                }
            });
        };

        const genRowClassName = (record: any) => {
            if (record.type && record.type === 'totalRow') {
                return 'weight';
            }
        };

        // 下载相关
        const downloadTableData = [
            {
                file: 'Report Data',
                sign: 2,
            },
        ];
        const downloadTypeVisible = ref(false);
        const download = () => {
            downloadTypeVisible.value = true;
        };
        const closeDownloadTypeModal = () => {
            downloadTypeVisible.value = false;
        };
        const handleOk = () => {
            const params = {
                url: `/rvapi/lr/regLicenseInfo/downloadReportDataInfo`,
                method: 'post',
                params: { ...genParams(), downloadType: 2 },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res: any) => {
                    downloadTypeVisible.value = false;
                }
            );
        };

        const handleDataViewChange = () => {
            genAllColumns(dataView.value);
            search();
        };

        const selectViewType2 = (e: Event) => {
            selectViewType(e);
            if (selectedViewType.value === 1) {
                genAllColumns(undefined);
            } else {
                genAllColumns(dataView.value);
            }
            search();
        };

        return {
            SHOW_PARENT,
            qmOptions,
            spaceSize,
            viewTypes,
            selectedViewType,
            selectViewType2,
            bu,
            buList,
            brand,
            brandList,
            make,
            makeList,
            typeClass,
            typeClassList,
            region,
            regionList,
            subRegion,
            subRegionList,
            province,
            provinceList,
            city,
            cityList,
            year,
            openYear,
            qm,
            handlerOpenYearChange,
            handlerPanelYearChange,
            restrictedCity,
            restrictedCityType,
            dealerName,
            dealerGroup,
            calculationRule,
            calculationRuleType,
            dataView,
            dataViewList,
            dealerCodeType,
            dealerCodeTypeList,
            columns,
            tableWidth,
            colHeight,
            dataSource,
            reset,
            download,
            search,
            genRowClassName,
            downloadTableData,
            downloadTypeVisible,
            closeDownloadTypeModal,
            handleOk,
            isVAN,
            handleDataViewChange,
        };
    },
});
